.supplement-user-info-tips {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: url("../../images/supplement-bg.png") no-repeat center bottom;
  background-size: 100% auto;
  .supplement-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .supplement-tips-icon {
    width: auto;
    height: 93px;
  }
  .page-title {
    font-size: 24px;
    line-height: 1;
    margin-top: 18px;
    color: #333;
  }
  .page-desc {
    font-size: 16px;
    line-height: 1;
    margin-top: 10px;
    color: #778b99;
  }
  .button-wrap {
    margin-top: 40px;
  }
}


