.user-agreement {
  font-size: 12px;
  width: 980px;
  margin: 20px auto;
  padding: 20px 50px;
  line-height: 24px;
  border: #ccc solid 1px;
  color: #000;
  background-color: #fff;
  .user-agreement-title {
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .text12b {
    font-weight: bold;
    padding-bottom: 24px;
  }
  .item {
    padding-bottom: 20px;
  }
}
