.supplement-user-info {
  .el-select {
    width: 100%;
  }
  .page-title {
    font-size: 24px;
    line-height: 1;
    color: #333;
    margin-top: 100px;
    text-align: center;
  }
  .edit-step {
    text-align: center;
    margin-top: 60px;
  }
  .form-box-wrap {
    width: 1200px;
    margin: 36px auto 50px;

    .el-form {
      margin: 0 150px;
    }
    .button-wrap {
      text-align: center;
      margin-top: 40px;
    }
    .explain {
      width: 100%;
      margin: 60px auto 0;
      background: #f6f4e0;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      position: relative;
      padding: 20px 40px;
      overflow: hidden;
      box-sizing: border-box;
      .close-button {
        position: absolute;
        right: 12px;
        top: 13px;
        font-size: 14px;
        color: #778b99;
        cursor: pointer;
      }
      .explain-cell {
        width: 100%;
        font-size: 18px;
        line-height: 1;
        color: #778b99;
        & + .explain-cell {
          margin-top: 15px;
        }
        span {
          font-weight: bold;
        }
      }
    }
    .real-name-wrap-bg {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
    .real-name-wrap {
      width: 400px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .qrcode-wrap {
        width: 400px;
        height: 450px;
        text-align: center;
        background: #fff;
        padding: 30px;
        img {
          width: 320px;
          height: 320px;
          overflow: hidden;
        }
        .qrcode-tips {
          font-size: 18px;
          line-height: 1;
          color: #778b99;
          margin-top: 22px;
          text-align: center;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .close-button-wrap {
        text-align: center;
        .qrcode-close-button {
          width: 36px;
          height: 36px;
          line-height: 32px;
          border: solid 2px #fff;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          margin-top: 30px;
          position: relative;
          left: 50%;
          margin-left: -18px;
          cursor: pointer;
        }
      }
    }
    .form-done-tips {
      font-size: 24px;
      line-height: 1;
      color: #333;
      text-align: center;
      margin: 56px auto 10px;
    }
    .form-done-desc {
      font-size: 18px;
      line-height: 1;
      color: #778b99;
      text-align: center;
    }
  }
}
