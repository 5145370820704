.custom-button {
  display: inline-block;
  height: 40px;
  border-radius: 4px;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  padding: 11px 10px;
  cursor: pointer;
  transition: background .2s, color .2s, border-color .2s;
  margin-right: 20px;
  min-width: 120px;
  text-align: center;
  &.small {
    font-size: 14px;
    height: 34px;
    line-height: 20px;
    padding: 7px 10px;
    min-width: 88px;
  }
  &.primary {
    background: linear-gradient(45deg, #1b9dfc, #1bc5fd);
    &:hover {
      background: linear-gradient(45deg, #0673ee, #06b5ee);
    }
    &:active {
      background: linear-gradient(45deg, #0647ee, #06a4ee);
    }
  }
  &.gray {
    background: #778b99;
    &:hover {
      background: #5c717f;
    }
    &:active {
      background: #4b6475;
    }
  }
  &.plain {
    background: none;
    border: 1px solid #1b9dfc;
    padding: 10px 10px;
    color: #1b9dfc;
    &:hover {
      border-color: #0673ee;
      color: #0673ee;
    }
    &:active {
      border-color: #0647ee;
      color: #0647ee;
    }
  }
  &.disabled {
    background: #e4e8eb;
    cursor: not-allowed;
  }
  &.loading {
    opacity: .8;
    cursor: not-allowed;
    pointer-events: none;
  }
  .iconfont {
    font-size: 18px;
    margin-right: 10px;
  }
  &:nth-last-child(1) {
    margin-right: 0;
  }
  .el-icon-loading {
    margin-right: 10px;
  }
}



