.login-input-wrap {
  height: 50px;
  position: relative;
  flex: 1;
  .login-input-tips {
    font-size: 12px;
    line-height: 1;
    color: #1b9dfc;
    position: absolute;
    left: 22px;
    top: 8px;
    z-index: 1;
  }
  .login-input-icon {
    position: absolute;
    left: 0;
    top: 5px;
    width: 40px;
    height: 40px;
    border-right: 1px solid #d0d8dd;
    text-align: center;
    .iconfont {
      font-size: 24px;
      line-height: 40px;
      color: #778b99;
    }
  }
  .login-input-required {
    position: absolute;
    left: 5px;
    top: 18px;
    font-size: 14px;
    line-height: 1;
    color: #f55;
    cursor: text;
  }
  .login-input-clear {
    position: absolute;
    right: 0;
    top: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    display: none;
    .iconfont {
      font-size: 18px;
      line-height: 40px;
      color: #778b99;
    }
  }
  .login-input {
    width: 100%;
    height: 100%;
    border: 1px solid #d0d8dd;
    border-radius: 4px;
    padding-left: 22px;
    padding-right: 40px;
    font-size: 14px;
    transition: border-color .2s, padding-top .2s;
    &:-ms-input-placeholder {
      color: #999;
    }
    &::-webkit-input-placeholder {
      color: #999;
    }
    &::-moz-placeholder {
      color: #999;
    }
    &:focus {
      border-color: #1b9dfc;
    }
  }
  &.active {
    .login-input {
      padding-top: 22px;
    }
  }
  &.error {
    .login-input-tips {
      color: #f55;
    }
    .login-input {
      border-color: #f55;
    }
  }
  &.input-icon {
    .login-input-tips {
      left: 62px;
    }
    .login-input {
      padding-left: 62px;
    }
  }
  &.required {
    .login-input {
      border-color: #f55;
      padding-left: 80px;
    }
  }
  &.required.input-icon {
    .login-input-required {
      left: 45px;
    }
    .login-input {
      padding-left: 120px;
    }
  }
  &:hover {
    .login-input-clear {
      display: block;
    }
  }
  &.disabled {
    .login-input {
      background: #eef2f4;
      cursor: not-allowed;
    }
    .login-input-clear {
      display: none !important;
    }
  }
}
