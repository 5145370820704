.password-valid-tips {
  width: 100%;
  padding: 10px 20px 20px;
  position: absolute;
  left: 0;
  top: 60px;
  background: #fff;
  box-shadow: 0 1px 17px 1px rgba(27, 157, 252, 0.17);
  z-index: 2;
  border-radius: 4px;
  animation: show ease-in .2s;
  &:after {
    content: '';
    position: absolute;
    left: 40px;
    top: -6px;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(45deg);
  }
  .title {
    font-size: 14px;
    line-height: 1;
    color: #778b99;
    .green {
      color: #2ac16e;
    }
    .yellow {
      color: #f7af02;
    }
    .red {
      color: #f55;
    }
  }
  .security-level {
    width: 245px;
    height: 6px;
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    margin-top: 8px;
    .item {
      flex: 1;
      background: #e4e8eb;
      & + .item {
        margin-left: 2px;
      }
    }
    .green {
      background: #2ac16e;
    }
    .yellow {
      background: #f7af02;
    }
    .red {
      background: #f55;
    }
  }
  .tips-text-wrap {
    margin-top: 14px;
    .tips-text {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      color: #778b99;
      .iconfont {
        font-size: 12px;
        line-height: 20px;
        margin-right: 10px;
      }
      .text {}
      &.success {
        .iconfont {
          color: #2ac16e;
        }
      }
      &.error {
        color: #f55;
      }
    }

  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
