.job-card {
  width: 100%;
  position: relative;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  .left-content {
    .logo {
      width: 60px;
      height: 60px;
      .img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .right-content {
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex: 1;
    background: #fff;
    padding: 10px 16px;
  }
  .top {
    display: flex;
    position: relative;
    .recommend-btn {
      // 垂直居中
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      .focus-btn {
        margin-right: 10px;
      }
    }

  }
  .job-info {
    flex: 1;
    .row01 {
      display: flex;
      cursor: pointer;

      .name {
        font-size: 18px;
        line-height: 20px;
        color: #1b9dfc;
        margin-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .salary {
        font-size: 20px;
        line-height: 1;
        color: #333;
      }
      .cooperate {
        font-size: 16px;
        line-height: 20px;
        color: #333;
        margin-left: 20px;
      }
      .salary-tag {
        font-size: 12px;
        line-height: 18px;
        color: #1b9dfc;
        padding: 0 5px;
        border: 1px solid #1b9dfc;
        border-radius: 4px;
        margin-left: 10px;
        display: inline-block;
      }
      .salary-tag-coo {
        font-size: 12px;
        line-height: 18px;
        color: #e72bfc;
        padding: 0 5px;
        border: 1px solid #e72bfc;
        border-radius: 4px;
        margin-left: 10px;
        display: inline-block;
      }
    }
    .row02 {
      display: flex;
      margin-top: 18px;
      .item {
        font-size: 14px;
        line-height: 1;
        color: #778b99;
        margin-right: 10px;
        padding-right: 10px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 1px;
          right: 0;
          height: 12px;
          border-right: 1px solid #dae0e6;
        }
        &:nth-last-child(1):after {
          content: none;
        }
      }
    }
    .row03 {
      font-size: 14px;
      line-height: 1;
      color: #778b99;
      margin-top: 18px;
      .color1 {
        color: #2ac16e;
      }
      .color2 {
        color: rgba(255, 85, 85, 0.3);
      }
      .color3 {
        color: rgba(255, 85, 85, 0.6);
      }
      .color4 {
        color: rgba(255, 85, 85, 1);
      }
    }
    .urgent-tag-0 {
      font-size: 12px;
      line-height: 18px;
      color: #20fc17;
      padding: 0 5px;
      border: 1px solid #20fc17;
      border-radius: 4px;
      margin-left: 10px;
    }

    .urgent-tag-1 {
      font-size: 12px;
      line-height: 18px;
      color: #fc8a82;
      padding: 0 5px;
      border: 1px solid #fc8a82;
      border-radius: 4px;
      margin-left: 10px;
    }
    .urgent-tag-2 {
      font-size: 12px;
      line-height: 18px;
      color: #fc5d57;
      padding: 0 5px;
      border: 1px solid #fc5d57;
      border-radius: 4px;
      margin-left: 10px;
    }
    .urgent-tag-3 {
      font-size: 12px;
      line-height: 18px;
      color: #fc0000;
      padding: 0 5px;
      border: 1px solid #FC0000;
      border-radius: 4px;
      margin-left: 10px;
    }
  }
  .company-info {
    position: relative;
    padding-right: 80px;
    cursor: pointer;
    .company-name {
      font-size: 14px;
      line-height: 20px;
      color: #778b99;
      margin-top: 8px;
      width: 240px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .company-desc {
      font-size: 14px;
      line-height: 1;
      color: #778b99;
      margin-top: 8px;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    .qr-code {
      width: 32px;
      height: 32px;
      cursor: pointer;
      // 鼠标悬浮放大
      &:hover {
        transform: scale(4);
      }
    }
    .left {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .commission {
        display: flex;
        flex-grow: 1;
        align-items: center;
        margin-right: 28px;
        .img {
          width: 18px;
          height: 18px;
          margin-top: 2px;
          margin-right: 4px;
        }
        .text {
          font-size: 14px;
          line-height: 22px;
          margin-right: 10px;
          &.red {
            color: #ff5555;
          }
        }
      }
      .time {
        font-size: 14px;
        line-height: 22px;
        color: #778b99;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      color: #1b9dfc;
      .focus {
        cursor: pointer;
      }
      .iconfont {
        font-size: 18px;
        margin-right: 4px;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .dialog-notice{
    padding-bottom: 20px;
    display: flex;
    align-items:center;
    p{
      padding-left: 4px;
      font-size: 16px;
      color: #333333;
    }
  }
  .dialog-explain{
    padding-top: 10px;
    border-top: 1px solid rgba(0,0,0,0.1);
    color: red;
    font-weight: 400;
  }
  .iconShare{
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .position{
    &-tag{
      display: inline-block;
      margin-left: 10px;
      padding: 0  6px;
      border-radius: 5px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
    }
    &-ka{
      border-color: #ffbb98;
        color: #fa541e;
        background-color: #fff2e8;
        font-weight: 500;
    }
    &-new{
      background: #67c23a;
        border-color: #67c23a;
        color: #ffffff;
    }
    &-urgentCall{
      background: red;
      border-color: red;
      color: #ffffff;
  }
  &-quick{
      background: #1690ff;
      border-color: #1690ff;
      color: #ffffff;
  }
  }
  .jobitem-card-toolbar {
    margin-top: 10px;
    .el-button{
        margin-left:0;
        margin-right: 10px;
    }
  }
}



