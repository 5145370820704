.footer-toolbar {
  position: fixed;
  right: 50px;
  bottom: 200px;
  width: 50px;
  height: 126px;
}
.footer-toolbar__qrcode-wrap {
  position: relative;
}
.footer-toolbar__qrcode-icon, .footer-toolbar__to-top {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 9px 0 rgba(105, 140, 165, 0.24);
  background: #fff;
  color: #000;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  transition: background .1s ease-in, color .1s ease-in;
  .iconfont {
    font-size: 34px;
  }
  &:hover {
    background: #1b9dfc;
    color: #fff;
     & + .footer-toolbar__qrcode {
       display: flex;
     }
  }
}
.footer-toolbar__qrcode {
  position: absolute;
  right: 70px;
  top: -21px;
  width: 180px;
  height: 92px;
  background: linear-gradient(90deg, #1bc5fd, #1b9dfc);
  box-shadow: 0 1px 17px 1px rgba(27, 133, 252, 0.48);
  padding: 9px;
  border-radius: 4px;
  display: none;
  animation: fadeIn .1s ease-in;
  &:after {
    content: '';
    position: absolute;
    top: 40px;
    right: -6px;
    width: 14px;
    height: 14px;
    background: #1b9dfc;
    transform: rotate(45deg);
  }
}
.footer-toolbar__qrcode-img {
  width: 75px;
  height: 75px;
}
.footer-toolbar__qrcode-text {
  margin-left: 6px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  text-align: justify;
  text-align-last: justify;
}
.footer-toolbar__to-top {
  margin-top: 26px;
  background: #778b99;
  color: #fff;
  animation: fadeIn .1s ease-in;
  .iconfont {
    font-size: 26px;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
