.page-login {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 780px;
  background: url("https://attachment.itneituiquan.com/%E5%86%85%E6%8E%A8%E5%9C%88%E7%8C%8E%E5%A4%B4%E7%AB%AF%E7%99%BB%E5%BD%95%E8%83%8C%E6%99%AF%E5%9B%BE.png") no-repeat center center;
  background-size: cover;
  min-width: 1400px;
  padding-bottom: 60px;
  overflow: auto;
  .login-switch-button {
    width: 86px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #ffffff;
    font-size: 14px;
    line-height: 38px;
    color: #fff;
    text-align: center;
    transition: background .2s, border-color .2s;
    cursor: pointer;
    &:hover {
      background: #0673ee;
      border-color: #0673ee;
    }
    &:active {
      background: #0647ee;
      border-color: #0647ee;
    }
  }

  .login-center-wrap {
    position: absolute;
    left: 0;
    top: 80px;
    bottom: 60px;
    width: 100%;
    .logo {
      position: absolute;
      right: 260px;
      top: -70px;
      display: flex;
    }
    .logo .img {
      width: auto;
      height: 26px;
      margin-top: 8px;
    }
    .logo .text {
      font-size: 18px;
      color: #fff;
      margin-left: 10px;
      position: relative;
      top: 4px;
    }
  }
  .login-center {
    width: 1000px;
    height: 600px;
    margin: -300px auto 0;
    position: relative;
    top: 50%;
    padding-top: 118px;
    .login-banner {
      width: 470px;
      height: 364px;
      border-radius: 5px;
      background: url("../../images/login-bg02.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 28px;
      padding-top: 86px;
      color: #fff;
      .title {
        font-size: 42px;
        line-height: 96px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 35px;
          height: 5px;
          background: #fff;
        }
      }
      .subtitle {
        font-size: 28px;
        line-height: 1;
        margin-top: 40px;
      }
    }
    .login-box-wrap {
      width: 440px;
      height: 520px;
      background: #fff;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;

      .login-switch-wrap {
        text-align: center;
        margin-top: 40px;
      }
      .login-form-wrap {
        min-height: 288px;
        padding: 40px 40px 0;

        .login-form-cell {
          display: flex;
          position: relative;
        }
        .login-form-cell + .login-form-cell {
          margin-top: 10px;
        }
        .captcha-wrap {
          width: 110px;
          height: 50px;
          background: #fff;
          border-radius: 4px;
          border: solid 1px #d0d8dd;
          margin-left: 10px;
          cursor: pointer;
        }
        .captcha {
          width: 100%;
          height: 100%;
        }
        .login-form-links {
          display: flex;
          justify-content: space-between;
          margin-top: 26px;
          &.justify-left {
            justify-content: left;
          }
          label {
            display: flex;
            position: relative;
            overflow: hidden;
            cursor: pointer;
          }
          input {
            position: absolute;
            left: -100px;
          }
          .icon-wrap {
            width: 18px;
            height: 18px;
            border: 2px solid #778b99;
            border-radius: 4px;
            text-align: center;
            line-height: 14px;
            margin-right: 6px;
            transition: border-color .1s ease-in;
            .iconfont {
              font-size: 14px;
              color: #fff;
              transition: color .1s ease-in;
            }
          }
          input:checked + .icon-wrap {
            border-color: #1b9dfc;
            .iconfont {
              color: #1b9dfc;
            }
          }
          .text {
            font-size: 14px;
            line-height: 1;
            color: #778b99;
            padding: 2px 0;
            transition: color .2s;
            &:hover {
              color: #5c717f;
            }
            &:active {
              color: #4b6475;
            }
          }
        }
        .login-form-forgot-button {
          font-size: 14px;
          line-height: 1;
          color: #778b99;
          padding: 2px 0;
          cursor: pointer;
          transition: color .2s;
          &:hover {
            color: #5c717f;
          }
          &:active {
            color: #4b6475;
          }
        }
        .login-form-protocol-button {
          font-size: 14px;
          line-height: 1;
          color: #1b9dfc;
          padding: 2px 0;
          cursor: pointer;
          transition: color .2s;
          &:hover {
            color: #0673ee;
          }
          &:active {
            color: #0647ee;
          }
        }
      }
      .third-login {
        text-align: center;
        .title {
          font-size: 14px;
          line-height: 1;
          color: #778b99;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            width: 100%;
            border-bottom: 1px solid #778b99;
            opacity: 0.2;
          }
        }
        .title-text {
          display: inline-block;
          padding: 0 28px;
          background: #fff;
          position: relative;
          z-index: 1;
        }
        .wechat-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #778b99;
          text-align: center;
          color: #fff;
          margin: 30px auto 0;
          cursor: pointer;
          .iconfont {
            font-size: 26px;
            line-height: 40px;
          }
        }
      }
      .qrcode-wrap {
        .qrcode-title {
          margin: 0 auto;
          text-align: center;
          &-h3 {
            font-size: 28px;
            margin-top: 30px;
          }
          &-desc {
            color: grey;
            margin-top: 20px;
            margin-bottom: 10px;
          }
        }
      }
      //.qrcode-tips {
      //  font-size: 18px;
      //  line-height: 1;
      //  color: #333;
      //  margin-top: 40px;
      //  text-align: center;
      //}
      .qrcode-switch-button-wrap {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .qrcode-switch-button {
        font-size: 14px;
        line-height: 1;
        position: relative;
        padding: 0 20px;
        cursor: pointer;
        color: #1b9dfc;
        transition: color .2s;
        &:hover {
          color: #0673ee;
        }
        &:active {
          color: #0647ee;
        }
        & + .qrcode-switch-button:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          height: 10px;
          border-left: 1px solid #1b9dfc;
        }
      }

      &.register-done-wrap {
        text-align: center;
        .register-done-icon {
          width: 80px;
          height: 80px;
          margin: 120px auto 0;
          .iconfont {
            font-size: 80px;
            color: #2ac16e;
          }
        }
        .title {
          font-size: 24px;
          line-height: 1;
          color: #333;
          margin-top: 20px;
        }
        .subtitle {
          font-size: 16px;
          line-height: 1;
          color: #778b99;
          margin-top: 8px;
          margin-bottom: 40px;
        }
        .contact-company-wrap {
          width: 285px;
          margin: 110px auto 0;
          display: flex;
          .company-qrcode-wrap {
            width: 100px;
            height: 100px;
            background: #f5f5f5;
            text-align: center;
            padding: 8px;
            margin-right: 18px;
            img {
              width: 84px;
              height: 84px;
            }
          }
          .company-wechat-wrap {
            flex: 1;
            text-align: left;
            .scan-tips {
              font-size: 18px;
              line-height: 1;
              color: #333;
              margin-bottom: 22px;
            }
            .contact-number-wrap {
              display: flex;
              margin-top: 10px;
            }
            .icon-wrap {
              width: 24px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              background: #778b99;
              border-radius: 12px;
              margin-right: 8px;
              .iconfont {
                font-size: 14px;
                color: #fff;
              }
            }
            .contact-number {
              font-size: 14px;
              line-height: 24px;
              color: #778b99;
            }
          }
        }
      }
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .login-tip{
    font-size: 12px;
    color: #bfbfbf;
    padding-bottom: 10px;
  }
}
