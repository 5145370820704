.share-btn{
  display: inline-flex;
}
.dialog-share-box{
    width: 70%;
}
.dialog-share{
    &-btn{
      display: flex;
      justify-content: center;
      padding-top: 20px;
    }
    &-title{
      text-align: center;
      font-size: 16px;
      color: #333333;
    }
    &-imgs{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      img{
        margin: 0 10px;
        width: 260px;
        border-radius: 10px;
      }
    }
}