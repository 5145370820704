.custom-tab-pane-wrap {
  margin-right: 58px;
  display: flex;
  &:nth-last-child(1) {
    margin-right: 0;
  }
}
.custom-tab-pane {
  font-size: 14px;
  line-height: 22px;
  color: #778b99;
  cursor: pointer;
  transition: font-size .2s,color .2s;

  &.active {
    font-size: 18px;
    color: #1b9dfc;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 8px;
      background-color: #baedfe;
    }
  }
  .text {
    position: relative;
    z-index: 1;
  }
}
