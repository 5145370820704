.content{
    overflow-y:auto;
    height: 100%;
    &-title{
        font-size: 15px;
        color:#20A0FF ;
        margin-bottom: 6px;
        font-weight: 600;
    }
    &-body{
        font-size: 14px;
        color: #333;
        margin-bottom: 30px;
    }
}
.custom-drawer__title-text{
    // color: #000;
    font-size: 16px;
    font-weight: 600;
}