.custom-toast {
  position: fixed;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
  padding: 10px 30px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  max-width: 400px;
  z-index: 10000;
}



