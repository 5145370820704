.reset-password {
  .page-title {
    font-size: 24px;
    line-height: 1;
    color: #333;
    margin-top: 100px;
    text-align: center;
  }
  .edit-step {
    text-align: center;
    margin-top: 60px;
  }
  .form-box-wrap {
    width: 380px;
    margin: 36px auto 0;
    .form-box-cell {
      display: flex;
      position: relative;
    }
    .form-box-cell + .form-box-cell {
      margin-top: 10px;
    }
    .captcha-wrap {
      width: 110px;
      height: 50px;
      background: #fff;
      border-radius: 4px;
      border: solid 1px #d0d8dd;
      margin-left: 10px;
      cursor: pointer;
    }
    .captcha {
      width: 100%;
      height: 100%;
    }
    .button-wrap {
      text-align: center;
      margin-top: 40px;
    }
    .form-done-tips {
      font-size: 24px;
      line-height: 1;
      color: #333;
      text-align: center;
      margin: 56px auto 106px;
    }
  }
}
