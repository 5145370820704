.qrcode-wrapper {
    border-radius: 12px;
    border: 1px solid #d0d8dd;
    padding: 15px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    width: 292px;
    height: 292px;
    img {
      width: 260px;
      height: 260px;
    }
  }