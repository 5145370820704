.sms-button {
  width: 110px;
  height: 50px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background .2s;
  &.disabled {
    background: #e4e8eb;
    cursor: not-allowed;
  }
  &.primary {
    background: #1b9dfc;
    &:hover {
      background: #0673ee;
    }
    &:active {
      background: #0647ee;
    }
  }
}
