.select-gender {
  display: flex;
  .radio-button1, .radio-button2 {
    width: 90px;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    color: #778b99;
    border: 1px solid #d0d8dd;
    cursor: pointer;
    &.active {
      line-height: 40px;
      color: #fff;
      border: none;
      background: linear-gradient(45deg, #1bc5fd, #1b9dfc);
    }
  }
  .radio-button1 {
    border-radius: 4px 0 0 4px;
  }
  .radio-button2 {
    border-radius: 0 4px 4px 0;
    margin-left: 10px;
  }
}
