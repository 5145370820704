.login-button {
  height: 50px;
  border-radius: 4px;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  margin-top: 20px;
  cursor: pointer;
  transition: background .2s;
  &.disabled {
    background: #e4e8eb;
    cursor: not-allowed;
  }
  &.primary {
    background: #1b9dfc;
    box-shadow: 0 1px 15px 1px rgba(27, 157, 252, 0.44);
    &:hover {
      background: #0673ee;
    }
    &:active {
      background: #0647ee;
    }
  }
}
